<template>
  <v-card>
    <v-card-title class="px-0 pb-2">
      <v-container class="px-0 py-0 mb-2">
        <v-row align="end" class="px-8">
          <v-col cols="auto">
            <v-btn
              height="29"
              class="label text-capitalize font-weight-regular btn-table"
              depressed
              color="secondary"
              :ripple="false"
            >
              <v-icon left>$business</v-icon>
              {{ $t('page_company_list_title') }}
            </v-btn>
          </v-col>

          <v-spacer></v-spacer>
          <v-col md="3" cols="12">
            <v-text-field
              v-model="search"
              dense
              outlined
              :placeholder="`${$t('search')}...`"
              hide-details
              v-on:keyup.13="getDataFromApi()"
            >
              <template slot="append">
                <v-icon @click="getDataFromApi()" size="26" left>
                  mdi-magnify
                </v-icon>
              </template>
            </v-text-field>
          </v-col>

          <v-col md="3" cols="12" v-if="!$route.query.status">
            <v-select
              clearable
              dense
              outlined
              v-model="selectedStatus"
              :items="statuses"
              item-value="value"
              item-text="text"
              :placeholder="$t('page_company_list_select_status_text')"
              hide-details
              append-icon="$customChevronDown"
              @change="getDataFromApi()"
            ></v-select>
          </v-col>
        </v-row>
      </v-container>
    </v-card-title>

    <v-card-text class="px-0 pb-0">
      <Table
        @row-clicked="handleRowClick"
        :number-of-pages="getCompaniesPagination.total_pages"
        :total-records="getCompaniesPagination.records_total"
        :options.sync="options"
        :headers="headers"
        :items="getCompanies"
        @update:options="updateTable"
      >
        <template v-slot:item.id="{ item }">
          <div class="primary--text">{{ item.id }}</div>
        </template>

        <template v-slot:item.subscription="{ item }">
          <template v-if="item.subscription">
            <div>{{ item.subscription.name }}</div>
            <div class="text-furigana-fade">
              {{ item.subscription.start_date }}
            </div>
          </template>
          <template v-else>-</template>
        </template>

        <template v-slot:item.status="{ item }">
          <div class="text-disconnect" v-if="item.status.id === 0">
            {{ $t('disconnect') }}
          </div>
          <div class="text-connect" v-if="item.status.id === 1">
            {{ $t('connect') }}
          </div>
        </template>

        <template v-slot:item.head_count="{ item }">
          <div v-if="item.head_count">{{ item.head_count.name }}</div>
          <div v-else>-</div>
        </template>

        <template v-slot:item.office="{ item }">
          <div>{{ item.office }}</div>
          <div class="text-furigana-fade">{{ item.office }}</div>
        </template>

        <template v-slot:item.current_business_year="{ item }">
          <template
            v-if="
              item.current_business_year &&
                item.current_business_year.start_date
            "
          >
            <div>
              {{ item.current_business_year.term }}期 / FY{{
                item.current_business_year.start_date.split('-')[0]
              }}
            </div>
            <div class="text-furigana-fade">
              {{ $t('registration') }}:
              {{ item.current_business_year.start_date.split('-')[0] }}
              -
              {{ item.current_business_year.start_date.split('-')[1] }}
            </div>
          </template>
          <template v-else>
            -
          </template>
        </template>

        <template v-slot:item.current_month_logins="{ item }">
          <div class="text-center">
            <div>{{ item.current_month_logins }}</div>
            <div class="text-furigana-fade">
              ({{ item.previous_month_logins }})
            </div>
          </div>
        </template>

        <template v-slot:item.last_login_date="{ item }">
          <div>{{ item.last_login_date }}</div>
        </template>

        <template v-slot:item.prefecture="{ item }">
          <div>{{ item.display_name }}</div>
          <div v-if="item.prefecture" class="text-furigana-fade">
            {{ item.prefecture.name }} , {{ item.city }}
          </div>
          <div v-else class="text-furigana-fade">-</div>
        </template>

        <template v-slot:item.type="{ item }">
          <div>{{ item.type.name }}</div>
        </template>

        <template v-slot:item.action="{ item }">
          <v-btn color="secondary" icon>
            <v-icon size="20" left>$users</v-icon>
            {{ item.users_count }}
          </v-btn>
          <v-btn color="secondary" icon>
            <v-icon size="14">$tripleDots</v-icon>
          </v-btn>
        </template>
      </Table>
      <v-divider></v-divider>
    </v-card-text>
  </v-card>
</template>

<script>
import Table from '@/components/admin/partials/Table/Table'
import { mapGetters } from 'vuex'
export default {
  name: 'index',
  components: { Table },
  data() {
    return {
      pageCount: 0,
      recordCount: 0,
      paginationData: [],
      companies: [],
      loading: true,
      options: {},
      headers: [
        { text: this.$t('id'), value: 'id', align: 'center' },
        { text: this.$t('page_company_list_table_header_plan'), value: 'subscription', align: 'center' },
        { text: this.$t('page_company_list_table_header_type'), value: 'type', align: 'center' },
        {
          text: this.$t('page_company_list_table_header_office'),
          value: 'prefecture',
          align: 'center',
          width: 150,
          sortable: false
        },
        { text: this.$t('page_company_list_table_header_status'), value: 'status', align: 'center' },
        { text: this.$t('page_company_list_table_header_number_of_employee'), value: 'head_count', align: 'center' },
        {
          text: this.$t('page_company_list_table_header_business_year'),
          value: 'current_business_year',
          align: 'center',
          sortable: false
        },
        { text: this.$t('page_company_list_table_header_monthly_login'), value: 'current_month_logins', align: 'center' },
        { text: this.$t('page_company_list_table_header_login'), value: 'last_login_date', align: 'center' },
        { text: this.$t('actions'), value: 'action', align: 'center', sortable: false }
      ],
      search: '',
      statuses: [
        { value: 1, text: this.$t('connect'), url_query: 'Connect' },
        { value: 0, text: this.$t('disconnect'), url_query: 'Disconnect' }
      ],
      selectedStatus: null,

      editItemDlg: false,
      isValid: true,

      editName: null,
      editNameKana: null,
      editItemId: null,
      editUserId: null,

      users: [],
      type: ''
    }
  },
  computed: {
    ...mapGetters(['getCompanies', 'getCompaniesPagination'])
  },
  created() {
    this.getDataFromApi()
  },
  watch: {
    '$route.query': function() {
      this.getDataFromApi()
    }
  },
  methods: {
    updateTable(e) {
      let obj = {}
      obj.page = e.page
      obj.paginate = e.itemsPerPage
      obj.sortBy = e.sortBy[0]
      obj.sortDesc = e.sortDesc[0]
      this.getDataFromApi(obj)
    },
    async getDataFromApi(e) {
      this.loading = true

      if (this.$route.query.status) {
        this.selectedStatus = this.statuses.find(
          item => item.url_query === this.$route.query.status
        ).value
      }

      if (this.$route.query.type) {
        this.type = this.$route.query.type
      } else {
        this.type = ''
      }

      let params = {}

      if (this.search != '') params.search = this.search

      if (this.selectedStatus !== null) params.status = this.selectedStatus

      if (this.search !== '') params.search = this.search

      if (this.type !== '') params.type = this.$route.query.type

      if (e !== undefined) {
        if (e.sortBy && e.sortDesc !== undefined) {
          let order = e.sortDesc ? 'desc' : 'asc'
          params.sort = `${e.sortBy}:${order}`
        }

        if (e.page !== undefined && e.paginate !== undefined) {
          params.page = e.page
          params.paginate = e.paginate
        }
      }

      await this.$store
        .dispatch('GET_COMPANY', params)
        .catch(() => {
          this.$store.dispatch('ALERT', {
            show: true,
            text: '何かがおかしい'
          })
        })
        .finally(() => (this.loading = false))
    },
    editItem(item) {
      this.editItemId = item.id
      this.editName = item.display_name
      this.editNameKana = item.name_kana
      this.editUserId = item.user_id
      this.editItemDlg = true
    },
    async syncCompanies() {
      this.loading = true
      await this.$store.dispatch('SYNC_COMPANY')
      this.loading = false
    },
    editItemConfirm() {
      this.editItemDlg = false
      this.$store
        .dispatch('EDIT_COMPANY', {
          id: this.editItemId,
          payload: {
            name_kana: this.editNameKana,
            user_id: this.editUserId
          }
        })
        .then(() => {
          this.getDataFromApi()
          this.editName = null
          this.editNameKana = null
          this.editUserId = null
        })
    },
    handleRowClick(value) {
      this.$router.push(`/admin/companies/${value.id}/performance`)
    }
  }
}
</script>

<style lang="scss" src="./style.scss" scoped></style>
